import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button } from 'lib'

export default function CTAButton(props) {
  const styles = useStyles(props)
  const text = props.text || props.children || 'Contact us'
  return (
    <a href='/proposal' className={`${styles.link} ${props.className}`}>
      <Button {...props}>{text}</Button>
    </a>
  )
}

const useStyles = makeStyles(theme => ({
  link: theme.typography.link,
}))
