import React from 'react'
import Typography from '@material-ui/core/Typography'

export default function Text(rawProps) {
  const { style, text, children, ...props } = rawProps
  const content = children || text
  return (
    <Typography className={style} {...props}>
      {content}
    </Typography>
  )
}
