import React, { useState } from 'react'
import { View, Text, Button, CenterWrapper } from 'lib'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Slide from '@material-ui/core/Slide'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/styles'
import { navigate } from 'gatsby'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import { FaBars, FaEnvelope, FaPhone, FaComments } from 'react-icons/fa'
import posed from 'react-pose'
import { Settings } from '_appSetup'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

function getButtons() {
  const buttons = [
    { text: 'Home', to: '/#top' },
    { text: 'Portfolio', to: '/#portfolio' },
    { text: 'Pricing', to: '/#pricing' },
    { text: 'Blog', to: '/blog/' },
    { text: 'Contact us ->', to: '/proposal', highlight: true },
  ]
  return buttons
}

function NavButtons(props) {
  const buttons = getButtons()
  return buttons.map((button, idx) => { return <NavButton key={idx} button={button} {...props}/> })
}

function MobileDropdown(props) {
  const buttons = getButtons()
  const theme = useTheme()
  const [state, setState] = useState({ isOpen: false })
  const styles = useStyles()
  return (
    <View>
      <Button onClick={() => setState({ isOpen: !state.isOpen })} className={styles.hamburger}>
        <FaBars size={30} color={props.transparent ? 'white' : theme.palette.primary.light}/>
      </Button>
      <SwipeableDrawer
        anchor='top'
        open={state.isOpen}
        onOpen={() => setState({ isOpen: true })}
        onClose={() => setState({ isOpen: false })}
        className={styles.drawer}
      >
        <CenterWrapper className={`${styles.centerWrapper} ${styles.drawerTopWrapper}`}>
          <View style={styles.logoWrapper} onClick={() => {
            navigate('/')
            setState({ isOpen: false })
          }}>
            <img src={`/images/technoquest-logo-nopad.png`} alt='TechnoQuest' className={styles.logoImage}/>
          </View>
          <Button onClick={() => setState({ isOpen: !state.isOpen })} className={styles.hamburger}>
            <FaBars size={30} color={theme.palette.primary.light}/>
          </Button>
        </CenterWrapper>
        <View className={styles.navButtonWrapperDrawer}>
          {buttons.map((button, idx) => { return <NavButton key={idx} button={button} mobile pressed={() => setState({ isOpen: false })}/> })}
        </View>
      </SwipeableDrawer>
    </View>
  )
}

function HideOnScroll(props) {
  const { children, window } = props
  const trigger = useScrollTrigger({ target: window ? window() : undefined })
  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  )
}

function NavButton(props) {
  const { text, to } = props.button
  const styles = useStyles(props)
  return (
    <View className={styles.navButtonTextWrapper}>
      {props.button.highlight ? (
        <Button wrapperStyle={styles.navButtonHighlight} href={to}>{text}</Button>
      ) : (
        <a
          className={`${styles.navButtonText} ${props.transparent && styles.navButtonTextTransparent}`}
          href={to}
          onClick={props.pressed}>
          {text}
        </a>
      )}
    </View>
  )
}

export default function HeaderNavBar(props) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const styles = useStyles(isMobile)
  const pose = props.transparent ? 'init' : 'white'
  // const contactBanner = renderContactBanner()
  return (
    <View>
      {/* <Hidden smDown>{contactBanner}</Hidden> */}
      <HideOnScroll {...props}>
        <AppBar color={props.transparent ? 'primary' : 'inherit'} className={styles.appBar}>
          <BackgroundColor pose={pose} className={styles.backgroundAnimation}/>
          <Toolbar disableGutters>
            <CenterWrapper className={styles.centerWrapper}>
              <View style={styles.logoWrapper} onClick={() => navigate('/')}>
                <img src={props.transparent ? `/images/technoquest-logo-nopad-white.png` : `/images/technoquest-logo-nopad.png`} alt='TechnoQuest' className={styles.logoImage}/>
              </View>
              <Hidden smDown>
                <View style={styles.navButtons}>
                  <NavButtons transparent={props.transparent}/>
                </View>
              </Hidden>
              <Hidden mdUp>
                <View style={styles.navButtonsMobile}>
                  <MobileDropdown transparent={props.transparent}/>
                </View>
              </Hidden>
            </CenterWrapper>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </View>
  )
}

const transition = {
  duration: 200,
}

const BackgroundColor = posed.div({
  init: {
    opacity: 0,
    transition,
  },
  white: {
    opacity: 1,
    transition,
  },
})

const useStyles = makeStyles(theme => ({
  logoWrapper: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  contactBanner: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.secondary.intense,
    height: theme.mixins.contactBanner.minHeight,
    zIndex: 10000,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  contactItemRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  contactBannerInnerWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  contactBannerText: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  contactBannerLink: {
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
      opacity: 0.8,
    },
    cursor: 'pointer',
  },
  contactBannerSpacing: {
    width: 50,
  },
  appBar: {
    boxShadow: '10px 10px 5px 0px rgba(0,0,0,0.0)',
    backgroundColor: 'transparent',
    width: '100%',
  },
  backgroundAnimation: {
    backgroundColor: 'white',
    position: 'absolute',
    opacity: 0,
    top: 0,
    left: 0,
    right: 0,
    height: theme.mixins.toolbar.minHeight,
  },
  logoImage: {
    height: 42,
    marginLeft: theme.spacing(4),
  },
  navButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
  },
  navButtonsMobile: {
    display: 'flex',
    alignItems: 'center',
  },
  navButtonTextWrapper: {
    padding: theme.spacing(0.5),
  },
  navButtonText: {
    ...theme.typography.link,
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    fontSize: 18,
    color: 'black',
    '&:hover': {
      opacity: 0.8,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
    },
  },
  navButtonTextTransparent: {
    color: 'white',
  },
  centerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 0,
  },
  hamburger: {
    background: 'transparent',
    '&:hover': {
      background: 'transparent',
    },
  },
  drawer: {
    textAlign: 'center',
  },
  drawerTopWrapper: {
    marginTop: theme.spacing(2),
  },
  navButtonWrapperDrawer: {
    margin: theme.spacing(2),
  },
  navButtonHighlight: {
    marginTop: theme.spacing(0.75),
    marginBottom: 0,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
    },
    alignSelf: 'center',
  },
}))
