import React from 'react'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'

/**
 * <Input/>
 *
 * @prop {string} label display label
 * @prop {string} value current field value
 * @prop {object} style styles to be applied to text component
 * @prop {function} onChange what to do when shit changes
 */

function Input(rawProps) {
  const { style, value, label, onChange, ...props } = rawProps

  const handleChange = event => {
    onChange(event.target.value)
  }

  return (
    <TextField
      label={label}
      className={style}
      value={value}
      onChange={handleChange}
      margin='normal'
      {...props}
    />
  )
}

export default withStyles(theme => ({
  root: {
    '& MuiInputBase-root': {
      color: 'white',
    },
    '& input': {
      color: 'white',
    },
    '& textarea': {
      color: 'white',
    },
    '& label': {
      color: 'white',
    },
    '& label.Mui-error': {
      color: '#e74c3c',
    },
    '& .MuiInputLabel-asterisk': {
      color: 'transparent',
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid white',
    },
    '& .MuiInput-underline:before &:hover': {
      borderBottom: '1px solid white',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '2px solid white',
    },
  },
}))(Input)

