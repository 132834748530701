import React from 'react'
import { View, HeaderNavBar, Footer, CenterWrapper, SEO } from 'lib'
import { makeStyles } from '@material-ui/styles'
import '_appSetup/Global.css'

export default function Page(props) {
  const styles = useStyles()
  const Wrapper = props.fullWidth ? React.Fragment : CenterWrapper
  return (
    <View style={styles.wrapper}>
      <SEO/>
      <Wrapper>
        {!props.disableNavbar && <HeaderNavBar transparent={props.transparentHeader}/>}
        <View style={[!props.disableTopPadding && styles.topPadded, props.center && styles.center]}>
          {props.children}
        </View>
      </Wrapper>
      <Footer/>
    </View>
  )
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    minHeight: '100vh',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  topPadded: {
    paddingTop: theme.mixins.toolbar.minHeight + theme.mixins.contactBanner.minHeight,
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.mixins.toolbar.minHeight,
    },
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
