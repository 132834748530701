/* eslint no-console: "off" */
/* eslint no-await-in-loop: "off" */

const BASE_URL = 'https://us-central1-technoquest-co-uk.cloudfunctions.net/webfunc'

const FAKE_MODE = false

function appendSlash(url) {
  if (!url.includes('?') && url.charAt(url.length - 1) != '/') {
    url += '/'
  }
  return url
}

async function Fetch(params) {
  if (FAKE_MODE) {
    console.info('[FAKE Fetch]', params)
    params.successCallback(params.responseData)
    return
  }

  let options = {
    full_url: false,
    json: true,
    multipart: false,
  }
  if (params.hasOwnProperty('options')) {
    options = params.options
  }
  let AUTH_HEADER
  if (options.multipart) {
    AUTH_HEADER = {
      Accept: '*/*',
      'Content-Type': 'multipart/form-data',
    }
  } else {
    AUTH_HEADER = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
  const AUTH_TOKEN = null // await store.getState().Session.token // COMBAK use auth
  if (AUTH_TOKEN) {
    AUTH_HEADER.Authorization = AUTH_TOKEN
  }
  const url = options.full_url
    ? params.url
    : appendSlash(BASE_URL + params.url)
  const data =
    params.data &&
    (options.multipart ? params.data : JSON.stringify(params.data))
  const headers = params.headers ? params.headers : AUTH_HEADER
  if (__DEV__) {
    console.log('> > > FETCH > > > ')
    console.info(params.method, { url, params, headers, data })
  }
  try {
    const response = await fetch(url, {
      method: params.method,
      mode: params.mode,
      body: data,
      headers: headers,
    })
    if (__DEV__) {
      console.log('response', response)
    }
    if (response && response.ok) {
      if (params.hasOwnProperty('successCallback')) {
        if (options.json) {
          try {
            const json = await response.json()
            params.successCallback(json)
          } catch (err) {
            console.warn('> > > > > > INTERNAL ERROR < < < < < <', { err })
            params.successCallback()
          }
        } else {
          params.successCallback()
        }
      }
    } else {
      if (__DEV__) {
        console.warn('> > > > > > SERVER ERROR < < < < < <', {
          AUTH_HEADER,
          url,
          params,
          response,
        })
      }
      if (params.hasOwnProperty('failureCallback')) {
        params.failureCallback(response)
      }
    }
  } catch (err) {
    if (__DEV__) {
      console.warn('> > > > > > NETWORK ERROR < < < < < <', {
        AUTH_HEADER,
        url,
        params,
        err,
      })
    }
    if (params.hasOwnProperty('failureCallback')) {
      params.failureCallback('NETWORK_ERROR')
    }
  }
}

export default Fetch
