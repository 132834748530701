import React from 'react'
import { Image } from 'lib'
import { makeStyles } from '@material-ui/styles'

export default function TechnoQuestBanner() {
  const styles = useStyles()
  return (
    <Image src='assets/technoquest-logo.png' className={styles.astronaut}/>
  )
}

const useStyles = makeStyles(theme => ({
  astronaut: {
    margin: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 240,
  },
}))
