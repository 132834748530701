/* eslint no-console: 'off' */
import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

export default function View(rawProps) {
  const { style, children, platform, ...props } = rawProps
  const classNames = Array.isArray(style) ? style.join(' ') : style
  if (platform) {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    if (platform == 'mobile' && !isMobile) {
      return null
    } else if (platform == 'desktop' && isMobile) {
      return null
    }
  }
  return (
    <div className={classNames} {...props}>
      {children}
    </div>
  )
}
