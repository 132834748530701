import React from 'react'
import { View } from 'lib'
import { makeStyles } from '@material-ui/styles'

export default function SectionWrapper(props) {
  const styles = useStyles()
  return (
    <View {...props} style={[styles.wrapper, props.style]}>
      {props.children}
    </View>
  )
}

const styles = theme => ({
  wrapper: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(5),
    },
  },
})

const useStyles = makeStyles(styles)
