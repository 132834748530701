import React from 'react'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/styles'
import { View } from 'lib'

export default function CenterWrapper(props) {
  const styles = useStyles(props)
  const Wrapper = props.backgroundColor ? View : React.Fragment
  const wrapperProps = props.backgroundColor && { style: styles.background }
  return (
    <Wrapper {...wrapperProps}>
      <Container className={`${styles.wrapper} ${props.className}`} maxWidth='lg'>
        {props.children}
      </Container>
    </Wrapper>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    zIndex: 10,
  },
  background: props => ({
    backgroundColor: props.backgroundColor,
  }),
}))
