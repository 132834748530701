import React from 'react'
import { View, Text } from 'lib'
import { makeStyles } from '@material-ui/styles'

export default function SectionHeader({ title, subtitle, darkBg = false }) {
  const styles = useStyles()
  return (
    <View className={styles.wrapper}>
      <Text variant={'h4'} className={`${styles.heading} ${darkBg ? styles.headingDark : ''}`} text={title}/>
      <View style={[styles.separator, subtitle && styles.marginBottom]}/>
      {subtitle && <Text style={`${styles.subtitleText} ${darkBg ? styles.subtitleTextDark : ''}`} variant={'subtitle1'} text={subtitle}/>}
    </View>
  )
}

const styles = theme => ({
  wrapper: {
    ...theme.centralise,
    textAlign: 'center',
    margin: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  heading: {
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'center',
    },
    fontSize: 32,
  },
  headingDark: {
    color: 'white !important',
  },
  separator: {
    width: 50,
    height: 4,
  },
  marginBottom: {
    marginBottom: 4,
  },
  subtitleText: {
    paddingBottom: theme.spacing(1),
    fontSize: 18,
    maxWidth: 600,
  },
  subtitleTextDark: {
    color: 'white !important',
  },
})

const useStyles = makeStyles(styles)
