import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, FormControlLabel, Checkbox } from '@material-ui/core'

function LabeledCheckbox(rawProps) {
  const { style, value, label, onChange, ...props } = rawProps

  return (
    <FormControl>

      <FormControlLabel
        className={style}
        margin='normal'
        control={<Checkbox
          classes={{
            root: props.classes.blue,
          }}
          disableRipple
          checked={value}
          onChange={onChange}
          name={label}/>}
        label={label}
        {...props}
      />
    </FormControl>
  )
}

export default withStyles(theme => ({
  root: {
    color: 'white',
    '& label.Mui-error': {
      color: '#e74c3c',
    },
    '& .MuiInputLabel-asterisk': {
      color: 'transparent',
    },
    '& label.Mui-focused': {
      color: 'white',
    },
  },
  label: {
    color: 'white',
  },
  blue: {
    color: 'white',
  },
}))(LabeledCheckbox)

