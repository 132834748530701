import React from 'react'
import posed from 'react-pose'
import { Text } from 'lib'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

export default function UIButton(rawProps) {
  const { wrapperStyle, text, children, ...props } = rawProps
  const styles = useStyles()
  const content = children || text

  return (
    <Animation className={`${styles.wrapper} ${wrapperStyle}`}>
      <Button
        disableRipple
        {...props}
        className={`${styles.button} ${props.big && styles.buttonBig} ${props.inverted && styles.buttonInverted} ${props.className} ${props.disabled && styles.disabled}`
        }>
        <Text className={`${styles.content} ${props.inverted && styles.contentInverted} ${props.big && styles.contentBig}`}>{content}</Text>
      </Button>
    </Animation>
  )
}

const Animation = posed.div({
  hoverable: true,
  init: {
    scale: 1,
  },
  hover: {
    scale: 1.08,
  },
})

const BUTTON_REG = 50
const BUTTON_BIG = 62


const useStyles = makeStyles(theme => ({
  wrapper: {
    marginTop: theme.spacing(1),
    display: 'flex',
  },
  button: {
    background: theme.palette.primary.light,
    height: BUTTON_REG,
    borderRadius: theme.spacing(4),
    margin: 0,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    flex: 1,
    '&:hover': {
      background: theme.palette.primary.light,
    },
  },
  buttonInverted: {
    background: theme.palette.secondary.intense,
    '&:hover': {
      background: theme.palette.secondary.intense,
    },
  },
  disabled: {
    opacity: 0.5,
  },
  buttonBig: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    height: BUTTON_BIG,
    borderRadius: theme.spacing(0.75),
  },
  content: {
    color: 'white',
    fontSize: 16,
    textDecoration: 'none',
    fontWeight: 600,
  },
  contentInverted: {
    color: 'white',
  },
  contentBig: {
    fontWeight: 600,
    fontSize: 24,
  },
}))
