import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { View, Text, CenterWrapper } from 'lib'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import { Settings } from '_appSetup'
import moment from 'moment'

const linksA = [
  { text: 'HOME', to: '/#' },
  { text: 'SERVICES', to: '/#services' },
  { text: 'PORTFOLIO', to: '/#portfolio' },
  { text: 'PRICING', to: '/#pricing' },
]

function renderLinkCol(arr) {
  const styles = useStyles()
  const links = arr.map(({ to, text }, index) => {
    return <a key={index} className={styles.link} href={to} to={to}>{text}</a>
  })
  return (
    <View style={styles.linkCol}>
      {links}
    </View>
  )
}

function Disclaimers(props) {
  const styles = useStyles(props)
  return (
    <Grid item {...props} direction='column'>
    </Grid>
  )
}

function Contact(props) {
  const styles = useStyles(props)
  return (
    <Grid item {...props} direction='column'>
      <a href='/#top'>
        <img src={'/images/technoquest-logo.png'} alt='TechnoQuest' className={styles.logoImage}/>
      </a>

      <Text className={styles.footerText}>Contact: {Settings.CONTACT_EMAIL}</Text>

      <Text className={styles.footerText}>

        <a href={Settings.TERMS_OF_USE_URL} className={styles.link} target='_blank' rel='noreferrer'>Terms of Use</a>{' • '}
        <a href={Settings.PRIVACY_POLICY_URL} className={styles.link} target='_blank' rel='noreferrer'>Privacy Policy</a>

      </Text>

      <Text className={styles.regDetailsText} text={`©${moment().format('YYYY')} TechnoQuest Ltd. Company no. 12518916, registered in England and Wales.`}/>

    </Grid>
  )
}

export default function Footer(props) {
  const styles = useStyles(props)
  return (
    <View style={styles.wrapper}>
      <CenterWrapper>
        <View className={styles.innerWrapper}>
          <Grid container spacing={2} direction='row'>
            {/* <Hidden smDown>
              <Contact sm={6} className={styles.left}/>
              <Disclaimers sm={6} className={styles.right}/>
            </Hidden> */}
            {/* <Hidden mdUp> */}
            <Contact xs={12} className={styles.center}/>
            <View style={styles.mobileDisclaimers}>
              <Disclaimers xs={12} className={styles.center}/>
            </View>
            {/* </Hidden> */}
          </Grid>
        </View>
      </CenterWrapper>
    </View>
  )
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    bottom: 0,
    background: theme.palette.secondary.main,
    paddingBottom: theme.spacing(5),
  },
  footerLinkWrapper: {
    display: 'flex',
    marginTop: theme.spacing(1),
  },
  linkCol: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    ...theme.typography.link,
    fontWeight: 'inherit',
    color: theme.typography.color,
    textDecoration: 'underline',
  },
  footerText: {
    marginTop: theme.spacing(1),
    // textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  innerWrapper: {
    padding: `${theme.spacing(6)}px ${theme.spacing(1)}px`,
  },
  center: {
    ...theme.centralise,
  },
  right: {
    ...theme.right,
  },
  text: {
    fontSize: 16,
    color: props => props.color,
  },
  regDetailsText: {
    fontSize: 11,
    opacity: 0.8,
    textAlign: 'center',
    marginTop: theme.spacing(6),
  },
  logoImage: {
    width: 250,
    marginBottom: theme.spacing(2),
  },
  mobileDisclaimers: {
    flex: 1,
  },
}))
